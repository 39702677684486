
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import genpicross from '../images/genpicross.png';
import genlaby from '../images/genlaby.png';
import gensudoku from '../images/gensudoku.png';
import genscrable from '../images/genscrable.png';
import genbombe from '../images/genbombe.png';
import gendes from '../images/gendes.png';
import genage from '../images/genage.png';
import gen1 from '../images/gen1.png';
import gen2 from '../images/gen2.png';
import gen3 from '../images/gen3.png';
import genlettre from '../images/genlettre.png';
import gengeo from '../images/gengeo.png';
export default class Presentation extends Component {

 
    render()
    {

        return <div>
             <Helmet>
                <title>Les challenges, des jeux de réflexion et de logique pour adultes et enfants gratuits </title>
                <meta name="description" content="Des challenges pour se détendre de façon intelligente avec des jeux de réflexion gratuits en ligne,  de nombreux thèmes sont proposés comme la géographie, la culture générale, l'orthographe..." />
            </Helmet>
            <h1 className="centre">Les challenges</h1>
            <p>Venez essayer nos petits jeux et faites fonctionner vos méninges. Tous nos jeux sont très faciles d'accès, on peut y jouer de 10 à 110 ans, ils sont gratuits et ne nécessitent aucune inscription. On vous demande simplement votre prénom pour établir un classement. </p>
    
       
         
        <h2>Jeux de réflexion</h2>
        <p>Plongez dans l'univers des jeux de réflexion avec nos défis captivants comme le Picross, le Sudoku, et les labyrinthes ! 🧩🧠 Ces jeux sont parfaits pour stimuler votre esprit, développer votre logique, et améliorer votre concentration. Que vous soyez amateur de chiffres, de casse-têtes visuels, ou de parcours complexes, il y en a pour tous les goûts.</p>

        <div className='plateauCategorie'>
        <Link to='/presentationcerebral'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Le grand challenge</div>
    <div className='imageCategorie'><img src={gen1} alt="catégorie challenge reflexion"></img></div>
        </div>
        </Link>
        <Link to='/presagemental'> <div className='categorieGeneral'>
    <div className='titreCategorie'>L'âge mental</div>
    <div className='imageCategorie'><img src={genage} alt="catégorie age mental"></img></div>
        </div>
        </Link>
      <Link to='/yam-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Yams</div>
    <div className='imageCategorie'><img src={gendes} alt="catégorie yams"></img></div>
        </div>
        </Link>
        <Link to='/sudoku-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Sudoku</div>
    <div className='imageCategorie'><img src={gensudoku} alt="catégorie sudoku"></img></div>
        </div>
        </Link>
        <Link to='/picross-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Picross</div>
    <div className='imageCategorie'><img src={genpicross} alt="catégorie picross"></img></div>
        </div>
        </Link>
        <Link to='/labyrinthe-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Labyrinthe</div>
    <div className='imageCategorie'><img src={genlaby} alt="catégorie labyrinthe"></img></div>
        </div>
        </Link>
        <Link to='/demineur-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Démineur</div>
    <div className='imageCategorie'><img src={genbombe} alt="catégorie démineur"></img></div>
        </div>
        </Link>
        </div>
      <h2>Jeux avec les mots</h2>
      <p>Plongez dans l'univers des jeux de mots avec nos puzzles passionnants tels que les mots codés, les anagrammes, et Motus ! 🔤🧩 Ces jeux sont parfaits pour enrichir votre vocabulaire, aiguiser votre esprit, et relever des défis linguistiques stimulants. Que vous aimiez décoder, réarranger des lettres, ou deviner des mots cachés, vous trouverez de quoi vous amuser tout en faisant travailler votre cerveau.</p>

      <div className='plateauCategorie'>
      <Link to='/presmot'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Le grand challenge</div>
    <div className='imageCategorie'><img src={genlettre} alt="catégorie challenge mot"></img></div>
        </div>
        </Link>
        <Link to='/mots-scrable'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Scrabble solitaire</div>
    <div className='imageCategorie'><img src={genscrable} alt="catégorie scrable"></img></div>
        </div>
        </Link>
        <Link to='/mots-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Pyramide</div>
    <div className='imageCategorie'><img src={gen2} alt="catégorie pyramide mot"></img></div>
        </div>
        </Link>
        <Link to='/mots-a-caser-presentation'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Mots à caser</div>
    <div className='imageCategorie'><img src={gen3} alt="catégorie mot à caser"></img></div>
        </div>
        </Link>
        </div>
        <h2>Français</h2>
            <p>Découvrez nos jeux éducatifs pour améliorer votre orthographe, conjugaison et vocabulaire de manière ludique et interactive ! 🎮📚 Que vous soyez débutant ou expert, ces jeux sont conçus pour renforcer vos compétences linguistiques tout en s'amusant. Idéals pour tous les âges, ils vous aideront à maîtriser la langue française tout en relevant des défis amusants. </p>
         
      <div className='plateauCategorie'>
      <Link to='/presconjugaison'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Conjugaison</div>
    <div className='imageCategorie'><img src={gen1} alt="catégorie conjugaison"></img></div>
        </div>
        </Link>
        <Link to='/presorthographe'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Orthographe</div>
    <div className='imageCategorie'><img src={gen2} alt="catégorie orthographe"></img></div>
        </div>
        </Link>
        <Link to='/presvocabulaire'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Vocabulaire</div>
    <div className='imageCategorie'><img src={gen3} alt="catégorie orthographe"></img></div>
        </div>
        </Link>
</div>
        <h2>Culture générale</h2>
                     <p>Découvrez notre jeu de plateau sur la culture générale, une expérience ludique qui mettra vos connaissances à l'épreuve tout en vous divertissant ! 🎲🧠 Parcourez des catégories variées – histoire, sciences, arts, géographie, et bien plus – pour répondre à des questions passionnantes et enrichir vos savoirs. En solo, en famille ou entre amis, chaque partie est une occasion de découvrir de nouvelles informations et de tester vos compétences. Prêt à devenir le maître du plateau ?</p>

      <div className='plateauCategorie'>
      <Link to='/presculture'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Culture générale</div>
    <div className='imageCategorie'><img src={gen1} alt="catégorie culture générale"></img></div>
        </div>
        </Link>
        </div>
        <h2>Géographie</h2>
                     <p>Découvrez nos jeux dédiés à la géographie, parfait pour explorer le monde depuis votre salon ! 🌍🎲 Testez vos connaissances sur les pays, les capitales, les montagnes, les fleuves, et bien plus encore. Ce jeu vous fera voyager à travers les continents tout en vous divertissant. Que vous soyez un globe-trotter dans l'âme ou un curieux de la planète, chaque partie est une aventure éducative et passionnante. Qui sera le champion de la géographie ?</p>

      <div className='plateauCategorie'>
      <Link to='/presgeographie'> <div className='categorieGeneral'>
    <div className='titreCategorie'>Géographie</div>
    <div className='imageCategorie'><img src={gengeo} alt="catégorie géographie"></img></div>
        </div>
        </Link>
       </div>
       <p>Pour toute question ou pour suivre notre actualité, c'est sur <a href="https://x.com/evalquiz">X </a>que cela se passe.</p>

<Ad></Ad>
        </div>
    }
  
}